import React from 'react'

interface PrivacyProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Privacy
}

const Privacy: React.FC<PrivacyProps> = () => (
  <section>
    <div className="container py-5 my-lg-5">
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <script
            id="CookieDeclaration"
            src="https://consent.cookiebot.com/6aa9605b-cd70-4db4-9fbf-fed552facc64/cd.js"
            type="text/javascript"
            async
          />
        </div>
      </div>
    </div>
  </section>
)

export default Privacy
